<!-- 用户登录页面 -->

<template>
  <div class="main">
    <div class="logo-img">
      <el-image
        style="width: 340px; height: 90.55px"
        :src="require('./../../../icon/logo-black.png')"
      ></el-image>
    </div>

    <!-- 登录页面 -->
    <div class="main-content">
      <div class="describe">
        <div class="title">灯塔智能选科与生涯服务系统</div>
      </div>
      <div class="login-wrap">
        <el-form
          label-position="left"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          class="demo-ruleForm login-container"
        >
          <h3 class="title">登录</h3>
          <el-form-item prop="username">
            <el-input
              type="text"
              v-model="ruleForm.username"
              auto-complete="off"
              placeholder="账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              auto-complete="off"
              placeholder="密码"
            ></el-input>
          </el-form-item>
          <el-checkbox
            class="remember"
            v-model="rememberpwd"
            @change="changeRememberPwd"
            >记住密码</el-checkbox
          >
          <el-form-item style="width: 100%">
            <el-button
              type="primary"
              style="
                width: 100%;
                background-color: #f17c67;
                border-color: #f17c67;
              "
              @click="LoginSubmitForm('ruleForm')"
              :loading="logining"
              >登录
            </el-button>
          </el-form-item>
          <div class="panel-search__tip">@copyright</div>
          <div class="out-link">
            <a
              class="out-link-item"
              href="https://xingaokao.career-explore.com/administrator/"
              >学校管理员登录</a
            >
            <a
              class="out-link-item"
              href="https://xingaokao.career-explore.com/consultor/"
              >咨询师登录</a
            >
          </div>
        </el-form>
      </div>
    </div>

    <!-- 完善信息 -->
    <div
      class="pop-wrapper"
      v-show="isLoggedIn && !InfoCompleted && infoPopDisplay"
    >
      <div class="login-bg"></div>
      <div class="login-wrapper">
        <div class="login">
          <p style="margin-bottom: 0.5rem">
            您好！欢迎使用灯塔智能生涯规划与数据分析系统！在开始使用前，您需要先完善一些信息。
          </p>
          <form class="lj-form query-form">
            <fieldset>
              <div class="lj-form-group">
                <label>学校</label
                ><input
                  class="lj-radius"
                  type="text"
                  :readonly="infoCollect.somereadonly"
                  name="school"
                  placeholder="请输入您的学校"
                  required=""
                  v-model="infoCollect.school.value"
                />
              </div>
              <div class="lj-form-group">
                <label>年级</label
                ><input
                  class="lj-radius"
                  type="text"
                  :readonly="infoCollect.somereadonly"
                  name="grade"
                  placeholder="请选择您的年级"
                  required=""
                  v-model="infoCollect.grade.value"
                />
              </div>
              <div class="lj-form-group">
                <label>姓名</label
                ><input
                  class="lj-radius"
                  type="text"
                  name="username"
                  placeholder="请输入您的姓名"
                  required=""
                  :readonly="infoCollect.somereadonly"
                  v-model="infoCollect.username.value"
                />
              </div>
              <div class="lj-form-group">
                <label>学号</label
                ><input
                  class="lj-radius"
                  type="text"
                  name="studentid"
                  placeholder="请输入您的学号"
                  required=""
                  :readonly="infoCollect.somereadonly"
                  v-model="infoCollect.studentid.value"
                />
              </div>
              <div class="lj-form-group">
                <label>性别</label>
                <div style="display: flex; align-items: center">
                  <div class="lj-radio">
                    <label
                      >男<input
                        type="radio"
                        name="gender"
                        value="男"
                        checked
                        v-model="infoCollect.gender.value"
                    /></label>
                  </div>
                  <div class="lj-radio">
                    <label
                      >女<input
                        type="radio"
                        name="gender"
                        value="女"
                        v-model="infoCollect.gender.value"
                    /></label>
                  </div>
                </div>
              </div>
              <!-- <div class="lj-form-group">
                <label>手机号</label
                ><input
                  class="lj-radius"
                  type="text"
                  name="phone"
                  placeholder="请输入您的手机号"
                  required=""
                  v-model="infoCollect.phone.value"
                />
              </div> -->
              <div class="button-wrapper">
                <button
                  class="lj-btn lj-btn-primary lj-btn-block"
                  @click.prevent="onInfoFinsih"
                >
                  填写完成
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>

    <!-- 首次登录修改密码 -->
    <div
      class="pop-wrapper"
      v-show="isLoggedIn && firstLogin && resetpwdDisplay"
    >
      <div class="login-bg"></div>
      <div class="login-wrapper" ref="loginWrapperDom">
        <div class="login">
          <div class="login-title">
            <span class="lj-icon-compass">灯塔智能生涯规划与数据分析系统</span>
          </div>
          <div class="login-describe">
            欢迎使用灯塔智能选科与生涯服务系统，首次登录需要您修改密码
          </div>
          <div class="lj-form login-form">
            <fieldset>
              <!-- <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="text"
                  name="number"
                  placeholder="请输入您的账号"
                  required=""
                  v-model="ruleForm.username"
                  oninput="(function(input){ input.value = input.value.trim() })(this)"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-user"></span>
                </div>
              </div> -->
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请输入您的密码"
                  required=""
                  v-model="ruleForm.password"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请输入您的新密码"
                  required=""
                  v-model="ruleForm.newpassword"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <div class="lj-form-group">
                <input
                  class="lj-radius"
                  type="password"
                  name="password"
                  placeholder="请确认您的新密码"
                  required=""
                  v-model="ruleForm.newpassword2"
                />
                <div class="input-icon-wrapper">
                  <span class="lj-icon-key"></span>
                </div>
              </div>
              <p>
                <button
                  class="lj-btn lj-btn-primary lj-btn-block"
                  @click.prevent="resetPwd"
                  style="margin-bottom: 1rem"
                >
                  重置密码
                </button>
              </p>
            </fieldset>
          </div>
          <hr />
          <p style="margin: 0">欢迎使用灯塔智能生涯规划与数据分析系统</p>
          <p style="margin-bottom: 0">希望我们的智能分析结果能给您带来帮助！</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  login,
  infoquery,
  FirstLoginResetPwd,
  PersonalInfo,checklogin
} from './../../../api/userinfo'
import md5 from 'js-md5'

export default {
  name: "login",
  data () {
    return {
      //定义loading默认为false
      logining: false,

      rememberpwd: false, // 记住密码
      ruleForm: {
        //username和password默认为空
        username: "",
        password: "",
        identity: 'student',
        newpassword:'',
        newpassword2:'',
      },
      //rules前端验证
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },

      infoPopDisplay: 1,
      resetpwdDisplay:1,

      infoCollect: {
        somereadonly: false,
        username: { value: '' },
        gender: { value: '' },
        school: { value: '' },
        grade: { value: '' },
        phone: { value: '' },
        studentid: { value: '' } //学号
      },
    };
  },
  mounted () {
      checklogin({
        identity: 'student'
      }).then(res=>{
        if(res.rescode==200){
            console.log('login firstlogin infocompleted',this.isLoggedIn,this.firstLogin,this.InfoCompleted)
            this.$store.commit('User/ChangeInfo', [res.InfoCompleted, null])
            this.$store.commit('User/ChangeFirstLogin', res.passwordchanged==0)

            if(this.isLoggedIn && !this.firstLogin && this.InfoCompleted){
               // 登录了且修改过密码和完善信息
                this.$router.replace({
                  path:'/'
                })
            }
            else if(this.isLoggedIn && this.firstLogin){
              // 首次登录没有修改密码
              this.resetpwdDisplay = 1
            }else if(this.isLoggedIn &&!this.InfoCompleted){
              // 登录了修改了密码，但没有完善信息
              this.onInfoPopClick()
            }
        }
      })
    this.getCookie()
  },
  computed:{
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
    InfoCompleted: function () {
      return this.$store.state.User.infoCompleted
    },
    firstLogin:function (){
      return this.$store.state.User.firstLogin
    }
  },
  methods: {
    // 设置cookie
    setCookie (c_name, c_pwd, c_state, exdays) {
      const exdate = new Date()
      exdate.setTime(exdate.getTime() + 7 * 60 * 60 * 1000 * exdays) // 保存的天数
      window.document.cookie = 'username' + '=' + c_name + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'password' + '=' + c_pwd + ';path=/;expires=' + exdate.toGMTString()
      window.document.cookie = 'state' + '=' + c_state + ';path=/;expires=' + exdate.toGMTString()
    },
    // 读取cookie
    getCookie () {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split('; ')
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split('=')
          console.log(arr[2])
          if (arr2[0] === 'username') {
            this.ruleForm.username = arr2[1]
          } else if (arr2[0] === 'password') {
            this.ruleForm.password = arr2[1]
          } else if (arr2[0] === 'state') {
            this.rememberpwd = Boolean(arr2[1])
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie('', '', false, -1)
    },
    changeRememberPwd: function () {
      // 判断复选框是否被勾选 勾选则调用配置cookie方法
      if (this.rememberpwd === true) {
        console.log('set cookie');
        this.setCookie(this.ruleForm.username, this.ruleForm.password, true, 7)
      } else {
        this.clearCookie()
      }
    },

    //点击登录按钮
    LoginSubmitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.logining = true;
          login({
            account: this.ruleForm.username,
            password: md5(this.ruleForm.password),
            identity:'student'
      })
        .then(res => {
          console.log('axios res', res)
          this.logining = false;
          if (res.code == 400) {
            this.$message.error('账号或者密码错误')
          } else if (res.rescode == 200 || res.rescode == 201) {
            this.ruleForm.password = ''
            this.ruleForm.newpassword = ''
            this.ruleForm.newpassword2 = ''
            this.$message.success('登录成功')
            let identity = res.identity
            this.$store.commit('User/LoginIn', [
              this.ruleForm.username,
              identity,
              res.username
            ])
            if (identity == 'student') {
              this.$store.commit('User/ChangeInfo', [res.InfoCompleted, null])
              this.$store.commit('User/ChangeFirstLogin', res.passwordchanged==0)

              if (this.firstLogin) {
                // 首次登录
                this.resetpwdDisplay = 1
              }
              else if(!this.InfoCompleted){
                // 登录了修改了密码，但没有完善信息
                this.onInfoPopClick()
              }
              else {
                // 已经重置过密码并且完善了所有信息
                this.$router.replace({
                    path:'/'
                })
              }
            }
          }
        })
        .catch(err => {
            this.logining = false;
        })
        } else {
          this.$message.error("请输入用户名和密码！");
          return false;
        }
      });
    },
    onInfoPopClose: function () {
      this.infoPopDisplay = !1
    },
    onInfoPopClick: function () {
      this.$message(
        '您好！欢迎使用灯塔智能生涯规划与数据分析系统！在开始使用前，您需要先完善一些信息。'
      )
      infoquery({}).then(res => {
        if (res.rescode == 200) {
          this.infoCollect.username.value = res.username
          this.infoCollect.gender.value = res.gender
          this.infoCollect.school.value = res.schoolname
          this.infoCollect.grade.value =
            res.enrollyear == 0 ? '' : res.enrollyear
          this.infoCollect.phone.value = res.phonenumber
          this.infoCollect.studentid.value = res.studentidnum
          this.infoPopDisplay = 1
          if (this.infoCollect.school.value != '') {
            this.infoCollect.somereadonly = true
          }
        }
      })

    },
    onResetpwdPopClose: function () {
      this.resetpwdDisplay = !1
    },
    /**
     * 填写完所有的信息
     */
     onInfoFinsih: function () {
      if (
        this.infoCollect.username.value == '' ||
        this.infoCollect.gender.value == '' ||
        this.infoCollect.school.value == '' ||
        this.infoCollect.grade.value == '' ||
        // this.infoCollect.phone.value == '' ||
        this.infoCollect.studentid.value == ''
      ) {
        this.$message.error('请将信息填写完整')
      } else {
        // axios发送信息
        PersonalInfo({
          username: this.infoCollect.username.value,
          gender: this.infoCollect.gender.value,
          schoolname: this.infoCollect.school.value,
          enrollyear: this.infoCollect.grade.value,
          phonenumber: this.infoCollect.phone.value,
          studentidnum: this.infoCollect.studentid.value
        }).then(res => {
          if (res.rescode == 200) {
            this.$message('信息保存成功')
            this.$store.commit('User/ChangeInfo', [
              1,
              this.infoCollect.username.value
            ])
            this.onInfoPopClose()
            this.$router.replace({
              path:'/'
            })
          }
        })
      }
    },
    resetPwd:function (){
      if (this.ruleForm.password == '') {
        this.$message.error('请输入原密码')
      }else if (this.ruleForm.newpassword == ''){
        this.$message.error('请输入新密码')
      } else if((this.ruleForm.newpassword != this.ruleForm.newpassword2)){
        this.$message.error('两次输入的密码不一致')
      } else {
        FirstLoginResetPwd({
          oldpassword:md5(this.ruleForm.password),
          newpassword: md5(this.ruleForm.newpassword),
        }).then(res => {
          if (res.rescode == 200) {
            this.$message('修改密码成功')
            this.$store.commit('User/ChangeFirstLogin', false)
            this.onResetpwdPopClose()
            if(!this.InfoCompleted){
              this.onInfoPopClick()
            } else {
              this.$router.replace({
                path:'/'
              })
            }
          }else{
            this.$message('修改密码错误')
          }
        })
      }
    }
  },
};
</script>

<style scoped lang="less">
@import url('./../../../Public/resources/css/templates/home/index/index.min.css');
.main {
  background: url('./../../../icon/bg1.jpg');
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  box-sizing: border-box;
  /* 背景图垂直、水平均居中 */
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
  /* 设置背景颜色，背景图加载过程中会显示背景色 */
  background-color: #ffffff;
  font-size: 14px;
}
.logo-img {
  display: flex;
  justify-content: right;
}
.main-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.describe {
  padding-top: 8%;
  .title {
    font-size: 4em;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: lighter;
    font-family: ZZGFSH;
    color: #fff;
  }
}

.login-wrap {
  box-sizing: border-box;
  padding-top: 8%;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100%;
}

@media (max-width: 414px) {
  .login-container {
    width: 400px;
  }
  .main-content {
    margin-bottom: 50px;
  }
}

@media (min-width: 800px) {
  .login-container {
    width: 440px;
  }
}
.login-container {
  margin: 0px auto;
  // width: 480px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  text-align: left;
}

.title {
  margin: 0px auto 20px auto;
  text-align: left;
  color: #505458;
  font-size: 1.3em;
}

.remember {
  margin: 0px 0px 35px 0px;
}

.forget {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
}

.panel-search__tip {
  font-size: 12px;
  color: #333;
}

.lj-nav-left {
  float: left;
}

.lj-form-group {
  margin-bottom: 0.5rem;
  align-items: center;
}

.lj-radio {
  margin: 0;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
}

.lj-checkbox + .lj-checkbox,
.lj-radio + .lj-radio {
  margin-top: 0;
}

.pop-wrapper {
  padding-top: 3%;
}

.phone {
  margin: 0 0 0.5rem;
  text-align: center;
  color: #000;
}

.login-describe {
  font-size: 1.4rem;
  padding-bottom: 2rem;
}

.out-link {
  display: flex;
  padding-top: 20px;
  .out-link-item {
    padding-right: 20px;
    cursor: pointer;
    font-size: 1.4rem;
    color: #555;
    text-decoration: underline;
    :hover {
      color: #000;
    }
  }
}
</style>
